import React from "react";
import Link from "./LocalizedLink";
import { Menu } from "@headlessui/react";
import { useTranslation } from "react-i18next";

// const Card = ({ header, children, to, ...rest }) => {
//   return (
//     <div className="flex-1 m-2">
//       <Link to={to} {...rest}>
//         <div className={`px-6 py-4 bg-gray-200 link-card h-full`}>
//           <h3 className="text-gray-800 text-lg font-semibold">{header}</h3>
//           <p className="text-gray-600">{children}</p>
//         </div>
//       </Link>
//     </div>
//   );
// };

const Card = ({ header, subheader, to, children }) => (
  <div className="flex-1">
    <Menu.Item as={Link} to={to}>
      <div className="px-6 py-4 h-full bg-gray-200 link-card">
        <h3 className="text-gray-800 text-lg font-semibold">{header}</h3>
        <p className="text-gray-600 text-sm">{subheader}</p>
        <p className="mt-1">{children}</p>
      </div>
    </Menu.Item>
  </div>
);

const ProductMenu = () => {
  const { t } = useTranslation("products");
  return (
    <div className="p-6 bg-gray-50 shadow-md">
      <div className="my-2">
        {/* <h2 className="text-xl font-semibold">
          <Menu.Item as={Link} to="/products/#seismic">
            Seismic Pro
          </Menu.Item>
        </h2>

        <p className="text-sm text-gray-500 mb-2">
          Comprehensive software package handling the full range of seismic
          survey methods from reflection, refraction, and surface wave to
          borehole seismic
        </p> */}
        <div className="flex space-x-4">
          {/* <Menu.Item as={Card} header="Utilities" to="/products/#utilities">
            Vertical stacking, wavefiled modeling, fence diagram, and much more.
          </Menu.Item>
          <Menu.Item as={Card} header="Reflection" to="/products/#reflection">
            Multi-fold and single-fold reflection data processing, GPR data
            processing
          </Menu.Item>
          <Menu.Item as={Card} header="Refraction" to="/products/#refraction">
            Conventional refraction methods like GRM, 2D and 3D refraction
            tomography
          </Menu.Item>
          <Menu.Item as={Card} header="Surface Wave" to="/products/#surface">
            Active and passive surface wave data processing, microtremor
            analysis
          </Menu.Item>
          <Menu.Item
            as={Card}
            header="Borehole Seismic"
            to="/products/#borehole"
          >
            Crosswell tomography, vertical seismic profiling, and well velocity
            survey
          </Menu.Item> */}
          <Card
            header={t("Seismic Pro")}
            subheader={t(
              "Comprehensive software package for near-surface seismic methods"
            )}
            to="/products/seismic/"
          ></Card>
          <Card
            header={t("Microseismic Pro")}
            subheader={t(
              "All-inclusive microseismic monitoring software package"
            )}
            to="/products/microseismic/"
          ></Card>
          <Card
            header={t("RImager")}
            subheader={t(
              "Resistivity and induced polarization tomography software package"
            )}
            to="/products/rimager/"
          ></Card>
        </div>
      </div>
      {/* <div className="my-2">
        <h2 className="text-xl font-semibold">
          <Link to="/products/#rimager">RImager</Link>
        </h2>
        <p className="text-sm text-gray-500 mb-2">
          Resistivity and induced polarization tomography software package
        </p>
        <div className="flex w-2/5 -mx-2">
          <Menu.Item as={Card} header="RTomo" to="/products/#rimager">
            Topographic Correction, resistivity tomography
          </Menu.Item>
          <Menu.Item as={Card} header="RViewer" to="/products/#rimager">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui,
            voluptatem!
          </Menu.Item>
        </div>
      </div>
      <div className="my-2">
        <Menu.Item as={Link} to="/products/" className="text-gray-600">
          See all products
        </Menu.Item>
      </div> */}
    </div>
  );
};

export default ProductMenu;
