const useBrowserLang = () => {
  if (typeof window === "undefined") {
    return null;
  }
  return (
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage ||
    window.navigator.systemLanguage ||
    null
  );
};

export default useBrowserLang;
