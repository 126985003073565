import React from "react";
import logo from "../../assets/images/logo-small.jpg";
import Link from "./LocalizedLink";
import { Link as GatsbyLink } from "gatsby";
import { BiChevronDown, BiMenu } from "react-icons/bi";
import ProductMenu from "./ProductMenu";
import { Menu, Transition } from "@headlessui/react";
import { LocaleContext } from "./Layout";
import { useTranslation } from "react-i18next";
import Event from "./Event";
import useBrowserLang from "../hooks/useBrowserLang";

const NavLink = ({ to, text, small = false, ...rest }) => {
  return (
    <Link
      to={to}
      className={`text-gray-600 text-lg flex items-center whitespace-nowrap ${
        small ? "px-2 py-1" : "px-2 xl:px-4 py-3"
      } transition hover:text-logoBlue hover:bg-logoBlue-light focus:outline-none focus-visible:ring-2`}
      activeClassName="text-gray-800"
      {...rest}
    >
      {text}
    </Link>
  );
};

const DropLink = ({ text, items, children, full = false }) => {
  const { locale } = React.useContext(LocaleContext);
  return (
    <>
      <Menu as="div" className={!full ? "relative" : ""}>
        {({ open }) => (
          <>
            <Menu.Button
              className={`px-2 xl:px-4 py-3 text-gray-600 ${
                open
                  ? "text-logoBlue bg-logoBlue-light"
                  : "hover:text-logoBlue hover:bg-logoBlue-light"
              } transition text-lg flex items-center whitespace-nowrap focus:outline-none focus-visible:ring-2`}
            >
              {text}
              {typeof text == "string" && (
                <BiChevronDown
                  className={`w-6 h-6 transition-transform ${
                    open ? "-rotate-180" : ""
                  }`}
                />
              )}
            </Menu.Button>
            <Transition
              as={React.Fragment}
              show={open}
              enter="transition duration-200 ease-out"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Menu.Items
                className={`absolute left-0 ${
                  full
                    ? items
                      ? "right-0 p-4 bg-gray-50 shadow-sm"
                      : "left-10 right-10 xl:left-40 xl:right-40"
                    : "w-40 shadow-sm p-2 bg-white"
                } origin-top-left flex flex-col space-y-1 mt-1`}
                static
              >
                {items &&
                  items.map(
                    ({ show, ...item }, idx) =>
                      (!show || show.includes(locale)) && (
                        <Menu.Item
                          as={NavLink}
                          key={idx}
                          {...item}
                          small={!full}
                        ></Menu.Item>
                      )
                  )}
                {children && <Menu.Item>{children}</Menu.Item>}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

const LocaleSelector = () => {
  const { locale, localizedPaths } = React.useContext(LocaleContext);
  const browserLang = useBrowserLang();
  if (locale !== "zh" && !browserLang?.startsWith("zh")) {
    return null;
  }
  return (
    <>
      <GatsbyLink
        to={localizedPaths.en}
        onClick={() => {
          try {
            sessionStorage.setItem("selectedLang", "en");
          } catch {}
        }}
        hrefLang="en"
        className={locale === "en" ? "text-gray-800" : ""}
      >
        EN
      </GatsbyLink>{" "}
      |{" "}
      <GatsbyLink
        to={localizedPaths.zh}
        onClick={() => {
          try {
            sessionStorage.setItem("selectedLang", "zh");
          } catch {}
        }}
        hrefLang="zh"
        className={locale === "zh" ? "text-gray-800" : ""}
      >
        中文
      </GatsbyLink>
    </>
  );
};

const Nav = () => {
  const { t } = useTranslation("common");
  const { locale } = React.useContext(LocaleContext);
  return (
    <nav className="bg-white sticky top-0 z-50 shadow">
      <Event />
      <div className="w-100 p-4">
        <div className="flex justify-between items-center h-12">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img src={logo} alt="Navigation Home" className="h-12 sm:h-16" />
            </Link>
            <div className="hidden lg:block">
              <div className="h-12 ml-10 flex items-center space-x-4">
                <NavLink to="/" text={t("Home")} />
                <DropLink text={t("Products")} full>
                  <ProductMenu />
                </DropLink>
                {/* <DropLink
                  text={t("Products")}
                  items={[
                    { to: "/products#seismic", text: t("Seismic Pro") },
                    { to: "/products#seismic", text: t("Microseismic Pro") },
                    { to: "/products#rimager", text: t("RImager") },
                  ]}
                /> */}
                {locale === "en" && (
                  <DropLink
                    text={t("Get Now")}
                    items={[
                      { to: "/trial/", text: t("Trial") },
                      {
                        to: "/purchase/info/",
                        text: t("Purchase"),
                        // show: ["en"],
                      },
                    ]}
                  />
                )}
                <DropLink
                  text={t("Support")}
                  items={[
                    { to: "/support/faq/", text: t("FAQs") },
                    { to: "/support/releases/", text: t("Release Notes") },
                    { to: "/support/downloads/", text: t("Downloads") },
                    { to: "/support/lectures/", text: t("Lectures") },
                  ]}
                />
                <NavLink to="/about/" text={t("About")} />
                <NavLink to="/contact/" text={t("Contact")} />
              </div>
            </div>
          </div>
          <div className="hidden lg:block text-gray-400">
            <LocaleSelector />
          </div>
          <div className="lg:hidden">
            <DropLink
              text={<BiMenu className="w-12 h-12" />}
              full
              items={[
                { to: "/products/", text: t("Products") },
                { to: "/trial/", text: t("Trial"), show: ["en"] },
                { to: "/purchase/info/", text: t("Purchase"), show: ["en"] },
                { to: "/support/faq/", text: t("Support") },
                { to: "/about/", text: t("About") },
              ]}
            >
              <div className="p-4 text-gray-400 text-lg">
                <LocaleSelector />
              </div>
            </DropLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
