import React from "react";
import logo from "../../assets/images/logo-white-small.png";
import Link from "./LocalizedLink";
import { useTranslation } from "react-i18next";
import { LocaleContext } from "./Layout";

const LinkGroup = ({ parent, links, children }) => {
  return (
    <div className="flex flex-col space-y-1 lg:space-y-4 w-28 overflow-visible whitespace-nowrap">
      <span className="font-bold uppercase text-gray-300">{parent}</span>
      {links.map((link) => (
        <Link key={link.text} to={link.to}>
          {link.text}
        </Link>
      ))}
      {children}
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation("common");
  const { locale } = React.useContext(LocaleContext);
  return (
    <footer className="bg-logoBlue text-gray-100 pb-3 text-sm lg:text-base">
      <div className="flex flex-col lg:flex-row p-6 border-b border-logoYellow">
        <div className="mb-4 lg:mr-36 lg:mb-0">
          <Link to="/" className="block mb-2 lg:mb-8">
            <img src={logo} alt="Navigation Home" className="h-10" />
          </Link>
          <p className="lg:mb-2">
            {t("Email")}:{" "}
            <a href="mailto:sales@geogiga.com" className="underline">
              sales@geogiga.com
            </a>
          </p>
          <p>
            {t("Phone")}:{" "}
            <a href="tel:+14034514886" className="underline">
              +1 403 451 4886
            </a>
          </p>
        </div>
        <div className="flex-grow flex flex-col space-y-4 lg:flex-row lg:space-y-0 justify-between lg:px-36">
          <LinkGroup
            parent={t("Products")}
            links={[
              { to: "/products/", text: t("All Products") },
              { to: "/products/seismic/", text: t("Seismic Pro") },
              { to: "/products/microseismic/", text: t("Microseismic Pro") },
              { to: "/products/rimager/", text: t("RImager") },
            ]}
          />
          {locale === "en" && (
            <LinkGroup
              parent={t("Get Now")}
              links={[
                { to: "/trial/", text: t("Trial") },
                { to: "/purchase/info/", text: t("Purchase") },
              ]}
            />
          )}
          <LinkGroup
            parent={t("Support")}
            links={[
              { to: "/support/faq/", text: t("FAQs") },
              { to: "/support/releases/", text: t("Release Notes") },
              { to: "/support/downloads/", text: t("Downloads") },
              { to: "/support/lectures/", text: t("Lectures") },
            ]}
          />
          <LinkGroup
            parent={t("About")}
            links={[
              { to: "/about/", text: t("About") },
              { to: "/contact/", text: t("Contact") },
            ]}
          />
        </div>
      </div>
      <p className="text-center mt-3 text-gray-300 text-xs lg:text-sm">
        &copy; 2001&ndash;2024 Geogiga Technology Corp.{" "}
        {t("All rights reserved.", { ns: "home" })}
      </p>
    </footer>
  );
};

export default Footer;
