module.exports = {
  en: {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
  },
  zh: {
    path: `zh`,
    locale: `zh-CN`,
    dateFormat: `YYYY-MM-DD`,
    siteLanguage: `zh`,
    ogLanguage: `zh_CN`,
  },
};
