import React, { useEffect } from "react";
import Footer from "./Footer";
import Nav from "./Nav";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";
import { navigate } from "gatsby";
import useBrowserLang from "../hooks/useBrowserLang";

export const LocaleContext = React.createContext();

const Layout = ({
  children,
  path,
  pageContext: { locale, localizedPaths },
}) => {
  const browserLang = useBrowserLang();
  useEffect(() => {
    const browserIsZh = browserLang?.startsWith("zh");
    const prefersEn = sessionStorage.getItem("selectedLang") === "en";
    if (browserIsZh && !prefersEn && locale !== "zh") {
      try {
        sessionStorage.setItem("selectedLang", "zh");
      } catch {
        return;
      }
      navigate(localizedPaths.zh);
    }
  }, [browserLang, locale]);

  const resources = { [locale]: {} };
  const options = {
    defaultNS: "common",
    ns: ["common", "home", "contact", "about", "support", "products", "trial"],
    nsSeparator: false,
    keySeparator: false,
    fallbackLng: false,
    initImmediate: false,
    interpolation: { escapeValue: false },
    react: {
      useSuspense: true,
    },
  };

  options.ns.forEach((name) => {
    if (locale === "zh") {
      const data = require(`../../i18n/translations/${locale}/${name}.json`);
      resources[locale][name] = data;
    }
  });

  i18n.init({ lng: locale, resources, ...options });

  return (
    <LocaleContext.Provider value={{ locale, localizedPaths }}>
      <I18nextProvider i18n={i18n}>
        <Nav />
        <div className="text-gray-800">
          <main className={path !== "/" ? "" : null}>{children}</main>
          <Footer />
        </div>
      </I18nextProvider>
    </LocaleContext.Provider>
  );
};

export default Layout;
