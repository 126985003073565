exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-microseismic-js": () => import("./../../../src/pages/products/microseismic.js" /* webpackChunkName: "component---src-pages-products-microseismic-js" */),
  "component---src-pages-products-rimager-js": () => import("./../../../src/pages/products/rimager.js" /* webpackChunkName: "component---src-pages-products-rimager-js" */),
  "component---src-pages-products-seismic-js": () => import("./../../../src/pages/products/seismic.js" /* webpackChunkName: "component---src-pages-products-seismic-js" */),
  "component---src-pages-purchase-after-approved-js": () => import("./../../../src/pages/purchase/after/approved.js" /* webpackChunkName: "component---src-pages-purchase-after-approved-js" */),
  "component---src-pages-purchase-after-declined-js": () => import("./../../../src/pages/purchase/after/declined.js" /* webpackChunkName: "component---src-pages-purchase-after-declined-js" */),
  "component---src-pages-purchase-info-js": () => import("./../../../src/pages/purchase/info.js" /* webpackChunkName: "component---src-pages-purchase-info-js" */),
  "component---src-pages-purchase-payment-js": () => import("./../../../src/pages/purchase/payment.js" /* webpackChunkName: "component---src-pages-purchase-payment-js" */),
  "component---src-pages-purchase-privacy-js": () => import("./../../../src/pages/purchase/privacy.js" /* webpackChunkName: "component---src-pages-purchase-privacy-js" */),
  "component---src-pages-support-downloads-js": () => import("./../../../src/pages/support/downloads.js" /* webpackChunkName: "component---src-pages-support-downloads-js" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-lectures-js": () => import("./../../../src/pages/support/lectures.js" /* webpackChunkName: "component---src-pages-support-lectures-js" */),
  "component---src-pages-support-releases-js": () => import("./../../../src/pages/support/releases.js" /* webpackChunkName: "component---src-pages-support-releases-js" */),
  "component---src-pages-trial-index-js": () => import("./../../../src/pages/trial/index.js" /* webpackChunkName: "component---src-pages-trial-index-js" */),
  "component---src-pages-trial-submitted-js": () => import("./../../../src/pages/trial/submitted.js" /* webpackChunkName: "component---src-pages-trial-submitted-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

